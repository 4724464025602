import React, { useState, useEffect } from 'react';
import { List, Datagrid, TextField, ImageField, ShowButton, BooleanField, Pagination } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import PopularCreateButton from '../popular-nft/popular-nft.create';
import { getFileType } from "../../helper/function";
import VideoSquareCover from "../../video-square.jpg";
import AudioSquareCover from "../../audio-square.jpg";


const useStyles = makeStyles({
    imgContainer: {
        '& img': {
            height: '60px',
            width: '60px',
            objectFit: 'contain',
            // borderRadius: '50%',
            margin: '10px',
        }
    }
})

const NFTShowButton = ({ record }) => (
    <ShowButton basePath="/nft" label="Show" record={record} />
)

const NFTPopularButton = ({ record }) => {
    return <PopularCreateButton record={record} />
}

const NFTPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

const CustomRowLayout = ({ record }) => {

    const classes = useStyles();
    const [ext, setExt] = useState(false);

    useEffect(() => {
        function getExtenstion() {
            if (!record.image.format) {
                let ext = getFileType(record.image.compressed);
                ext.then(function (result) {
                    setExt(result)
                })
            } else {
                setExt(record.image.format)
            }
        }
        getExtenstion();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record]);

    if (ext && ext === `image`) return <ImageField source="image.original" sortable={false} label="Image" className={classes.imgContainer} />
    if (ext && ext === `audio`) return <img src={AudioSquareCover} width='60px' height='60px' style={{ "margin": "10px" }} />
    if (ext && ext === `video`) return <img src={VideoSquareCover} width='60px' height='60px' style={{ "margin": "10px" }} />
    else return `loading..`
}

const NFTList = (props) => {

    const classes = useStyles();

    return (
        <List {...props}
            bulkActionButtons={false}
            title="List of NFTs"
            perPage={20}
            pagination={<NFTPagination />}
            style={{ backgroundColor: 'currentcolor' }}
        >
            <Datagrid style={{ tableLayout: 'fixed' , backgroundColor: 'currentcolor' }}>
                <TextField source="id" sortable={false} style={{ wordBreak: "break-all" , color: 'white' }} />
                <CustomRowLayout />
                <TextField source="title" sortable={false} style={{ overflow: "hidden", textOverflow: "ellipsis", WebkitLineClamp: "2", "display": "-webkit-box", WebkitBoxOrient: "vertical" , color: 'white' }} />
                <TextField source="description" sortable={false} style={{ overflow: "hidden", textOverflow: "ellipsis", WebkitLineClamp: "2", "display": "-webkit-box", WebkitBoxOrient: "vertical" , color: 'white' }} />
                <BooleanField source="isActive" sortable={false} style={{ color: 'white' }} />
                <NFTShowButton source="View" style={{ color: 'white' }} />
                <NFTPopularButton source="Action" style={{ color: 'white' }} />
            </Datagrid>
        </List >
    )
}

export default NFTList;
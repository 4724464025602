//using the infura.io node, otherwise ipfs requires you to run a daemon on your own computer/server. See IPFS.io docs
// const ipfsApi = require('ipfs-api');
// const ipfs = ipfsApi('ipfs.18.192.53.115.nip.io'); // stagging IPFS server

// const ipfsHttpClient = require("ipfs-http-client");
// const ipfs = ipfsHttpClient.create({
//   host: "ipfs.infura.io",
//   port: "5001",
//   protocol: "https",
// });


// const ipfsHttpClient = require("ipfs-http-client");
//  const projectId= "2DR7gBMSZscwTN42p9PCUfpgaxy" ;
// const projectSecret= "26593bb7eeca416f9531e6f7fcc32e25";
// const auth =
//     'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');
// const ipfs = ipfsHttpClient.create({
//   host: "ipfs.infura.io",
//   port: "5001",
//   protocol: "https",
//   ProjectName : "blocsysdev",
//   apiPath: '/api/v0',
//   headers: {
//     authorization: auth,
// },
// });


// export default ipfs;
import axios from "axios";


async function ipfs(formData) {
    try {

      const resFile = axios({
        method: "post",
        url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
        data: formData,
        headers: {
          pinata_api_key: "dab88606bedb72f386f1",
          pinata_secret_api_key:"25589d81e2991ea53e50780f71c27cafc1aa14e73c5c90ff5bb31ef2dfd39645",
          "Content-Type": "multipart/form-data",
        },
      })
      return resFile;
      
    } catch (err){
      console.log("pinata err==>",err);
    }
}
export default ipfs;

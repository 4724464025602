import axios from "axios";

// production server
const apiURL=process.env.REACT_APP_baseURL;
console.log("baseurllll==>",apiURL);
export const api = axios.create({
  // baseURL: "http://api.43.204.181.147.nip.io/api/v1/",
  baseURL: apiURL
});

// export const apiUrl = "http://api.43.204.181.147.nip.io/api/v1/";
export const apiUrl = "https://apiheadshots.blocsys.com/api/v1/"
// aws s3 bucket confiurations
export const awsRegion = "eu-central-1";
export const awsIdentityPoolId =
  "eu-central-1:949b9487-ed35-4eb3-a2d3-1866e6ad890b";
export const awsBucket = "avangrat-development";

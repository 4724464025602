import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  SimpleForm,
  TextInput,
  ImageField,
  ImageInput,
  useRedirect,
  useNotify,
  required,
  SelectInput,
  SimpleShowLayout,
  SelectArrayInput,
} from 'react-admin';
import fileToArrayBuffer from 'file-to-array-buffer';
import { services } from '../../services';
import { actions } from '../../actions';
import ipfs from '../../config/ipfs';
import Loader from '../loading';

const CollectionCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [callAPI, setCallAPI] = useState(false);
  const [creators, setCreators] = useState([]);
  const [categories, setCategory] = useState([]);
  const [buffer, setBuffer] = useState(null);
  const [params, setParams] = useState({
    name: null,
    description: null,
    ownerId: null,
    category: [],
  });

  useEffect(() => {
    async function getCreators() {
      const roles = await actions.getRoles();
      const roleId = roles
        .filter((role) => role.roleName === 'CREATOR')
        .map((role) => role.id)[0];
      const creators = await actions.getUsersByRoleId(roleId);
      setCreators(creators);
    }
    getCreators();

    async function getCategory() {
      const categories = await actions.getCategories();
      setCategory(categories.data);
    }
    getCategory();

    async function addCollection() {
      const request = services.post(`/nft/addCollection`, params);
      request
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            setLoading(false); // stop loading.
            throw new Error(response.statusText);
          }
          if (response.status === 200) {
            console.log(response.data);
            setLoading(false); // stop loading.
            notify(response.data.message, `success`);
            redirect('/collections');
          }
        })
        .catch((e) => {
          setLoading(false); // stop loading.
          if (e.response) {
            if (e.response.status === 401 || e.response.status === 403) {
              notify(`Unauthorized Request`, `error`);
              localStorage.removeItem('token');
              redirect('/collections');
            }
            // other error code (404, 500, etc): no need to log out
          } else {
            notify(`Bad request`, `error`);
            redirect('/collections');
          }
        });
    }

    if (callAPI) {
      addCollection();
    }
  }, [callAPI]);

  const onSuccess = async (params) => {
    setLoading(true); // start loader

    const formData = new FormData();
    formData.append("file", buffer);
    let hash = await ipfs(formData, { // get buffer IPFS hash
      pin: true, progress: (bytes) => {
        // console.log("File upload progress 1 ", Math.floor(bytes * 100 / (event.english.rawFile.size)))
      }
    })
    setParams({
      logo: hash.data.IpfsHash,
      name: params.name,
      description: params.description,
      ownerId: params.creatorId,
      category: params.category,
    });
    setCallAPI(true);
  };

  return loading ? (
    <Loader />
  ) : (
    <SimpleForm save={onSuccess} style={{ backgroundColor: 'currentcolor' }}>
      <ImageInput
        source='logo'
        label='Collection Logo'
        placeholder='Upload the collection logo.'
        accept='image/*'
        multiline={false}
        validate={required()}
        onChange={async (file) => {
          // fileToArrayBuffer(file).then((buffer) => {
            setBuffer(file)
          // })
        }}
      >
        <ImageField source='src' title='Collection logo' />
      </ImageInput>
      <TextInput source='name' />
      <TextInput source='description' />
      <SelectInput
        source='creatorId'
        choices={creators}
        optionValue='id'
        optionText={(creator) => `${creator.name} - ${creator.email}`}
        validate={required()}
      />

      <SimpleShowLayout>
        <SelectArrayInput
          source='category'
          choices={categories}
          optionValue='id'
          optionText={(category) => `${category.categoryName.en}`}
          validate={required()}
        />
      </SimpleShowLayout>
    </SimpleForm>
  );
};

export default CollectionCreate;

import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ShowButton,
  ImageField,
  ArrayField,
  ReferenceArrayField,
  SingleFieldList,
  Pagination,
  ChipField,
} from 'react-admin';
import PopularCreateButton from '../popular-collection/popular-collection.create';

const useStyles = makeStyles({
  imgContainer: {
    '& img': {
      height: '50px',
      width: '50px',
      objectFit: 'contain',
    },
  },
});

const collectionRowClick = (id, basePath, record) =>
  record.editable ? 'edit' : 'show';

const CollectionShowButton = ({ record }) => (
  <ShowButton basePath='/collections' label='Show' record={record} />
);

const CollectionPopularButton = ({ record }) => {
  return <PopularCreateButton record={record} />;
};

const CollectionPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

const CollectionList = (props) => {
  const classes = useStyles();

  return (
    <List {...props}
      bulkActionButtons={false}
      title='List of Collections'
      perPage={20}
      pagination={<CollectionPagination />}
      style={{ backgroundColor: 'currentcolor' }}
    >
      <Datagrid style={{ backgroundColor: 'currentcolor' }}>
        <TextField source='id' sortable={false} style={{ color: 'white' }} />
        <ImageField
          source='logo'
          sortable={false}
          label='Image'
          className={classes.imgContainer}
        />
        <TextField source='name' sortable={false} style={{ color: 'white' }} />
        <TextField source='description' sortable={false} style={{ color: 'white' }} />
        <BooleanField source='isActive' sortable={false} style={{ color: 'white' }} />
        <ArrayField source='category'>
          <SingleFieldList>
            <ChipField source='categoryName.en' />
          </SingleFieldList>
        </ArrayField>
        <CollectionShowButton source='View' style={{ color: 'white' }} />
        <CollectionPopularButton source='Action' style={{ color: 'white' }} />
      </Datagrid>
    </List>
  );
};

export default CollectionList;

import { useState, useEffect, useRef } from "react";
import fileToArrayBuffer from "file-to-array-buffer";
import FormData from 'form-data';
import {
  SimpleForm,
  TextInput,
  useRedirect,
  useNotify,
  SelectInput,
  NumberInput,
  required,
  ImageInput,
  ImageField,
  SelectArrayInput,
  SimpleShowLayout,
  FileInput,
  FileField,
} from "react-admin";
import { actions } from "../../actions";
import { services } from "../../services";
import Loader from "../loading";
import { web3 } from "../../web3";
import { compressImage } from "../../helper/function";
import ipfs from "../../config/ipfs";

const NFTCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [showPercentage, setShowPercentage] = useState(false);
  const [categories, setCategories] = useState([]);
  const [creators, setCreators] = useState([]);
  const [co_creators, setCoCreators] = useState([]);
  const [collections, setCollections] = useState([]);
  const [data, setData] = useState({ unlockContent: false, platformFees: 0 });
  const [token, setToken] = useState(null);
  const [callAPI, setCallAPI] = useState(false);
  const [nftData, setNFTData] = useState();
  const [buffer, setBuffer] = useState({ original: null, compressed: null });
  const [size, setSize] = useState({ original: 0, compressed: 0 });
  const [web3Data, setWeb3Data] = useState(null);
  const [uploadType, setUploadType] = useState(null);
  const [nftContractInstance, setNftContractInstance] = useState(null);

  const [file, setFile] = useState(null);
  const videoInput = useRef();
  const videoElem = useRef();

  const saleState = [
    { name: "AUCTION", value: "AUCTION" },
    { name: "BUY NOW", value: "BUY" },
  ];

  useEffect(() => {
    async function getNftContractInstance() {
      const nftContractInstance = await actions.getNFTContractInstance();
      setNftContractInstance(nftContractInstance);
    }
    getNftContractInstance();

    async function getWeb3Data() {
      const web3Data = await services.getWeb3();
      setWeb3Data(web3Data);
    }
    getWeb3Data();

    async function getCateogries() {
      const categories = await actions.getCategories();
      setCategories(categories.data);
    }
    getCateogries();

    async function getCreators() {
      const roles = await actions.getRoles();
      const roleId = roles
        .filter((role) => role.roleName === "CREATOR")
        .map((role) => role.id)[0];
      const creators = await actions.getAllUsersByRoleId(roleId);
      setCreators(creators);
      setCoCreators(creators);
    }
    getCreators();
  }, []);

  useEffect(() => {
    if (token) {
      mintingNFT();
      setToken(null);
    }
  }, [token]);

  useEffect(() => {
    if (callAPI) {
      createNFT();
      setCallAPI(false);
    }
  }, [callAPI]);

  const getUserCollections = async (creator) => {
    const collection = await actions.getUserCollections(creator);
    setCollections(collection.data);
    let co_creators = creators
      .filter((data) => data.id !== creator)
      .map((user) => user);
    setCoCreators(co_creators);
  };

  const mintingNFT = async () => {
    await nftContractInstance.methods
      .mintToken(
        data.edition,
        token,
        getAddress(data.ownerId, creators),
        data.coCreator
          ? getAddress(data.coCreator.userId, creators)
          : "0x0000000000000000000000000000000000000000",
        data.coCreator ? 100 - data.coCreator.percentage : "100",
        data.coCreator ? data.coCreator.percentage : "0",
        data.saleState === "AUCTION" ? "1" : "0",
        data.auctionTime ? data.auctionTime : Number(0),
        web3.utils.toWei(data.price.toString(), "ether"),
        data.platformFees ? data.platformFees : "0",
        // "0x7862A31a1a1184882CEe3Aec91Bcc93B0512D09F"
        "0xFA0A30bd853a29a09038772A96E66DB09BB6Be59"
      )
      .send({ from: web3Data.accounts[0] })
      .on("transactionHash", (hash) => {
        console.log("transaction hash : ", hash);
        // setTransactionId(hash)
        console.log(hash);
      })
      .on("receipt", (receipt) => {
        console.log("on receipt ", receipt);
        onReciept(receipt);
      })
      .on("error", (error) => {
        console.log("on error ", error); // error.code===4001 user reject the metamask transaction
        onTransactionError(error);
      });
  };

  const onReciept = (receipt) => {
    setLoading(false); // stop loading
    notify("NFT added successfully", `success`);
    redirect("/nft");
  };

  const onTransactionError = (error) => {
    setLoading(false); // stop loading
    notify("NFT can not be added successfully", `error`);
  };

  const createNFT = async () => {
    const request = services.post(`/nft/addNft`, data);
    request
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          setLoading(false); // stop loading.
          throw new Error(response.statusText);
        }
        if (response.status === 200) {
          console.log(response.data);
          setToken(response.data.data._id);
        }
      })
      .catch((e) => {
        setLoading(false); // stop loading.
        if (e.response) {
          if (e.response.status === 401 || e.response.status === 403) {
            notify(`Unauthorized Request`, `error`);
            localStorage.removeItem("token");
            redirect("/nft");
          }
          // other error code (404, 500, etc): no need to log out
        } else {
          notify(`Bad request`, `error`);
          redirect("/nft");
        }
      });
  };

  const onSuccess = async (event) => {
    setLoading(true); // start loader
    const image = data.image;
    let original_hash,
      compressed_hash = null;
    if (uploadType === "video") {
      // generate video thumbnail)
      const canvas = document.createElement("canvas");
      canvas.width = videoElem.current.videoWidth;
      canvas.height = videoElem.current.videoHeight;
      canvas
        .getContext("2d")
        .drawImage(
          videoElem.current,
          0,
          0,
          videoElem.current.videoWidth,
          videoElem.current.videoHeight
        );
      let imgSrc = canvas.toDataURL();
      const formData = new FormData();
            formData.append("file", buffer.original);
      original_hash = await ipfs(formData, {
        // get buffer IPFS hash
        pin: true,
        progress: (bytes) => {
          console.log(
            "Original File upload progress ",
            Math.floor((bytes * 100) / size.original)
          );
        },
      });
      fetch(imgSrc)
        .then((res) => res.blob())
        .then(async (blob) => {
          const thumbnailFile = new File([blob], "video_thumbnail", {
            type: "image/png",
          });
          const formData = new FormData();
            formData.append("file", thumbnailFile);
          compressed_hash = await ipfs(formData, {
            // get thumbnail IPFS hash
            pin: true,
            progress: (bytes) => {
              console.log(
                "Original File upload progress ",
                Math.floor((bytes * 100) / thumbnailFile.size)
              );
            },
          });
          setData({
            ...data,
            image: {
              original: original_hash.data.IpfsHash,
              compressed: compressed_hash.data.IpfsHash,
              format: uploadType,
            },
          });
          setCallAPI(true);
        });
    } else {
      if (image.size > 1572864) {
        // 1572864 1.5 mb
        const formData = new FormData();
            formData.append("file", buffer.original);
        original_hash = await ipfs(formData, {
          // get buffer IPFS hash
          pin: true,
          progress: (bytes) => {
            console.log(
              "Original File upload progress ",
              Math.floor((bytes * 100) / size.original)
            );
          },
        });
        const formData1 = new FormData();
            formData1.append("file", buffer.compressed);
        compressed_hash = await ipfs(formData1, {
          // get buffer IPFS hash
          pin: true,
          progress: (bytes) => {
            console.log(
              "Compressed File upload progress ",
              Math.floor((bytes * 100) / size.compressed)
            );
          },
        });
      } else {
        const formData = new FormData();
            formData.append("file", buffer.original);
        original_hash = await ipfs(formData, {
          // get buffer IPFS hash
          pin: true,
          progress: (bytes) => {
            console.log(
              "Original File upload progress ",
              Math.floor((bytes * 100) / size.original)
            );
          },
        });
        compressed_hash = original_hash;
      }
      setData({
        ...data,
        image: {
          original: original_hash.data.IpfsHash,
          compressed: compressed_hash.data.IpfsHash,
          format: uploadType,
        },
      });
      setCallAPI(true);
    }
  };

  const getAddress = (id, creators) => {
    const address = creators
      .filter((data) => data.id === id)
      .map((user) => user.walletAddress)[0];
    return address;
  };

  return loading ? (
    <Loader />
  ) : (
    <SimpleForm save={onSuccess} style={{ backgroundColor: 'currentcolor' }}>
      <h4 style={{color: 'white'}}>Item Description</h4>
      <SimpleShowLayout>
        <SelectInput
          source="fileType"
          choices={[
            { name: "Image", id: "image" },
            { name: "Audio", id: "audio" },
            { name: "Video", id: "video" },
          ]}
          optionValue="id"
          optionText={(type) => `${type.name}`}
          onChange={(event) => {
            setUploadType(event.target.value);
          }}
        />

        {uploadType === `image` ? (
          <ImageInput
            source="image"
            label="NFT Image"
            placeholder="Upload the nft image."
            accept="image/*"
            multiline={false}
            validate={required()}
            onChange={async (file) => {
              let fileType = file.type;
              if (
                file.size > 1572864 &&
                !fileType.search("image") &&
                !fileType.includes("gif")
              ) {
                let compFile = await compressImage(file);
                // let compBuffer = await fileToArrayBuffer(compFile).then(
                //   (comp) => {
                //     return comp;
                //   }
                // );
                // let originBuffer = await fileToArrayBuffer(file).then(
                //   (origin) => {
                //     return origin;
                //   }
                // );
                setBuffer({
                  ...buffer,
                  original: file,
                  compressed: compFile,
                });
                setSize({
                  ...size,
                  original: file.size,
                  compressed: compFile.size,
                });
              } else {
                // fileToArrayBuffer(file).then((data) => {
                  setBuffer({ ...buffer, original: file, compressed: file });
                // });
                setSize({
                  ...size,
                  original: file.size,
                  compressed: file.size,
                });
              }
              setData({ ...data, image: file });
            }}
            
          >
            <ImageField source="src" title="NFT image" />
          </ImageInput>
        ) : (
          ``
        )}

        {uploadType === `video` ? (
          <FileInput
            source="video"
            label="NFT Video"
            ref={videoInput}
            accept="video/*"
            validate={required()}
            placeholder="Upload the nft video."
            onChange={async (file) => {
              setFile(URL.createObjectURL(file));
              // fileToArrayBuffer(file).then((data) => {
                setBuffer({ ...buffer, original: file, compressed: file });
              // });
              setSize({ ...size, original: file.size, compressed: file.size });
              setData({ ...data, image: file });
            }}
          >
            <FileField source="src" title="NFT Video" />
          </FileInput>
        ) : (
          ``
        )}

        {uploadType === `audio` ? (
          <FileInput
            source="audio"
            label="NFT Audio"
            accept="audio/*"
            validate={required()}
            placeholder="Upload the nft audio."
            onChange={async (file) => {
              // fileToArrayBuffer(file).then((data) => {
                setBuffer({ ...buffer, original: file, compressed: file });
              // });
              setSize({ ...size, original: file.size, compressed: file.size });
              setData({ ...data, image: file });
            }}
          >
            <FileField source="src" title="NFT Audio" />
          </FileInput>
        ) : (
          ``
        )}

        {file && ( // for generating thumbnail
          <video
            style={{ display: "block" }}
            height={220}
            width={450}
            id="video"
            ref={videoElem}
            src={file}
            type="video/mp4"
            controls
          ></video>
        )}

        <TextInput
          source="title"
          validate={required()}
          onChange={(event) => setData({ ...data, title: event.target.value })}
        />
        <TextInput
          source="description"
          validate={required()}
          onChange={(event) =>
            setData({ ...data, description: event.target.value })
          }
        />
      </SimpleShowLayout>

      <h4 style={{color: 'white'}}>Select Creator</h4>
      <SimpleShowLayout>
        <SelectInput
          source="ownerId"
          choices={creators}
          optionValue="id"
          optionText={(creator) => `${creator.name} - ${creator.email}`}
          onChange={(event) => {
            setData({ ...data, ownerId: event.target.value });
            getUserCollections(event.target.value);
          }}
        />
      </SimpleShowLayout>

      <h4 style={{color: 'white'}}>Co-Creator</h4>
      <SimpleShowLayout>
        <SelectInput
          source="userId"
          choices={co_creators}
          optionValue="id"
          optionText={(creator) => `${creator.name} - ${creator.walletAddress}`}
          onChange={(event) => {
            setData({ ...data, coCreator: { userId: event.target.value } });
            setShowPercentage(true);
          }}
        />
        {showPercentage ? (
          <NumberInput
            source="percentage"
            validate={required()}
            onChange={(event) => {
              setData({
                ...data,
                coCreator: {
                  ...data.coCreator,
                  percentage: event.target.value,
                },
              });
            }}
          />
        ) : (
          ""
        )}
      </SimpleShowLayout>

      <h4 style={{color: 'white'}}>Category & Collection</h4>
      <SimpleShowLayout>
        <SelectArrayInput
          source="category"
          choices={categories}
          optionValue="id"
          optionText={(category) => category.categoryName.en}
          onChange={(event) => {
            setData({ ...data, category: event.target.value });
          }}
          validate={required()}
        />
        <SelectInput
          source="collectionId"
          choices={collections}
          optionValue="id"
          optionText={(collection) => `${collection.name}`}
          onChange={(event) => {
            setData({ ...data, collectionId: event.target.value });
          }}
        />
      </SimpleShowLayout>

      <h4 style={{color: 'white'}}>Marketplace Settings</h4>
      <SimpleShowLayout>
        <SelectInput
          source="saleState"
          choices={saleState}
          optionValue="value"
          optionText={(sale) => `${sale.name}`}
          onChange={(event) => {
            setData({ ...data, saleState: event.target.value });
            if (event.target.value === "AUCTION") {
              setShowTime(true);
            } else {
              setShowTime(false);
            }
          }}
          validate={required()}
        />

        {showTime ? (
          <NumberInput
            source="auctionTime"
            validate={required()}
            max={504}
            onChange={(event) => {
              setData({ ...data, auctionTime: event.target.value });
            }}
          />
        ) : (
          ""
        )}

        <NumberInput
          source="edition"
          validate={required()}
          onChange={(event) => {
            setData({ ...data, edition: event.target.value });
            setNFTData({ ...nftData, _editions: event.target.value });
          }}
        />
        <NumberInput
          source="price"
          validate={required()}
          onChange={(event) => {
            setData({ ...data, price: event.target.value });
            setNFTData({ ...nftData, _pricePerNFT: event.target.value });
          }}
        />
      </SimpleShowLayout>

      <h4 style={{color: 'white'}}>Unlockable Content</h4>
      <SimpleShowLayout>
        <TextInput
          source="digitalKey"
          onChange={(event) => {
            if (event.target.value) {
              setData({
                ...data,
                digitalKey: event.target.value,
                unlockContent: true,
              });
            } else {
              setData({ ...data, unlockContent: false });
            }
          }}
        />
      </SimpleShowLayout>

      <h4 style={{color: 'white'}}>Platform Fee</h4>
      <SimpleShowLayout>
        <NumberInput
          source="platform fee"
          initialValue={data.platformFees}
          max={50}
          onChange={(event) => {
            setData({ ...data, platformFees: event.target.value });
          }}
        />
      </SimpleShowLayout>
    </SimpleForm>
  );
};

export default NFTCreate;

import { useState, useEffect } from "react";
import {
    SimpleForm, TextInput, required, ImageInput, ImageField, useRedirect,
    useNotify, BooleanInput, DeleteButton, TopToolbar, Toolbar, SaveButton
} from 'react-admin';
import fileToArrayBuffer from 'file-to-array-buffer';
import dataProvider from "../../authentication/dataProvider";
import { services } from "../../services";
import Loader from "../loading";
import ipfs from "../../config/ipfs";
import BackButton from "../backButton";


const ProfileInfoEdit = (props) => {

    const notify = useNotify();
    const redirect = useRedirect();
    const [loading, setLoading] = useState(false);
    const [callAPI, setCallAPI] = useState(false);
    const [buffer, setBuffer] = useState({ en: null, tu: null });
    const [mobBuffer, setMobBuffer] = useState({ en: null, tu: null });
    const [params, setParams] = useState({ id: null, url: null, status: null, banner: null, mobile: null })

    useEffect(() => {

        async function fetchProfileInfo() {
            const response = await dataProvider.getOne('profile-info', { id: props.id })
            setParams({
                id: response.data.id, url: response.data.url, status: response.data.isActive,
                banner: { en: response.data.banner.en, tu: response.data.banner.tu },
                mobile: { en: response.data.mobile.en, tu: response.data.mobile.tu }
            })
        }
        fetchProfileInfo()

        async function updateProfileInfo() {
            const request = services.put(`admin/profile-info/update/${params.id}`, params);
            request.then((response) => {
                setLoading(false) // stop loading.
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                if (response.status === 200) {
                    notify(response.data.message, `success`)
                    redirect('/profile-info')
                }
            })
                .catch((e) => {
                    setLoading(false) // stop loading.
                    if (e.response) {
                        if (e.response.status === 401 || e.response.status === 403) {
                            notify(`Unauthorized Request`, `error`)
                            localStorage.removeItem('token');
                            redirect('/profile-info')
                        }
                        // other error code (404, 500, etc): no need to log out
                    } else {
                        notify(`Bad request`, `error`)
                        redirect('/profile-info')
                    }
                });
        }

        if (callAPI) {
            updateProfileInfo()
        }

    }, [callAPI])

    const onSuccess = async (event) => {
        setLoading(true) // start loader

        let { banner, mobile } = event;
        let banner_ = {
            en: params.banner.en.substring(params.banner.en.lastIndexOf('/') + 1),
            tu: params.banner.tu.substring(params.banner.tu.lastIndexOf('/') + 1),
        }
        let mobile_ = {
            en: params.mobile.en.substring(params.mobile.en.lastIndexOf('/') + 1),
            tu: params.mobile.tu.substring(params.mobile.tu.lastIndexOf('/') + 1),
        }

        if (buffer.en) {
            banner_.en = await ipfs.add(buffer.en, { // get buffer IPFS hash
                pin: true, progress: (bytes) => {
                    // console.log("File upload progress 1 ", Math.floor(bytes * 100 / (banner.en.rawFile.size)))
                }
            })
            banner_.en = banner_.en.path
        }

        if (buffer.tu) {
            banner_.tu = await ipfs.add(buffer.tu, { // get buffer IPFS hash
                pin: true, progress: (bytes) => {
                    // console.log("File upload progress 1 ", Math.floor(bytes * 100 / (banner.tu.rawFile.size)))
                }
            })
            banner_.tu = banner_.tu.path
        }

        if (mobBuffer.en) {
            mobile_.en = await ipfs.add(mobBuffer.en, { // get buffer IPFS hash
                pin: true, progress: (bytes) => {
                    // console.log("File upload progress 1 ", Math.floor(bytes * 100 / (mobile.en.rawFile.size)))
                }
            })
            mobile_.en = mobile_.en.path
        }

        if (mobBuffer.tu) {
            mobile_.tu = await ipfs.add(mobBuffer.tu, { // get buffer IPFS hash
                pin: true, progress: (bytes) => {
                    // console.log("File upload progress 1 ", Math.floor(bytes * 100 / (mobile.tu.rawFile.size)))
                }
            })
            mobile_.tu = mobile_.tu.path
        }
        setParams({
            ...params, url: event.url,
            status: event.status,
            banner: banner_,
            mobile: mobile_,
        })
        setCallAPI(true)
    }

    const PreviewImage = ({ record, source }) => {
        if (typeof (record) == "string") {
            record = {
                [source]: record
            }
        }
        return <ImageField record={record} source={source} />
    }

    const ProfileInfoActionToolbar = () => (
        <TopToolbar>
            <BackButton color='primary' style={{ marginLeft: '5rem' }} />
        </TopToolbar>
    )

    const ProfileInfoEditToolbar = props => (
        <Toolbar {...props} >
            <SaveButton />
            <DeleteButton />
        </Toolbar>
    )

    return (
        loading ? <Loader /> : (params.id ? <>
            <SimpleForm save={onSuccess} toolbar={<ProfileInfoEditToolbar />} >
                <ProfileInfoActionToolbar />
                <TextInput disabled label="Id" source="id" initialValue={params.id} />
                <ImageInput initialValue={params.banner.en} source="banner.en" label="Desktop Image for English" placeholder="Upload the image." accept="image/*" multiline={false} validate={required()}
                    onChange={async (file) => {
                        fileToArrayBuffer(file).then((data) => {
                            setBuffer({ ...buffer, en: data })
                        })
                    }}
                >
                    <PreviewImage source="en" />
                </ImageInput>
                <ImageInput initialValue={params.banner.tu} source="banner.tu" label="Desktop Image for Turkish" placeholder="Upload the image." accept="image/*" multiline={false} validate={required()}
                    onChange={async (file) => {
                        fileToArrayBuffer(file).then((data) => {
                            setBuffer({ ...buffer, tu: data })
                        })
                    }}
                >
                    <PreviewImage source="src" />
                </ImageInput>

                <ImageInput initialValue={params.mobile.en} source="mobile.en" label="Mobile Image for English" placeholder="Upload the image." accept="image/*" multiline={false} validate={required()}
                    onChange={async (file) => {
                        fileToArrayBuffer(file).then((data) => {
                            setMobBuffer({ ...mobBuffer, en: data })
                        })
                    }}
                >
                    <PreviewImage source="en" />
                </ImageInput>
                <ImageInput initialValue={params.mobile.tu} source="mobile.tu" label="Mobile Image for Turkish" placeholder="Upload the image." accept="image/*" multiline={false} validate={required()}
                    onChange={async (file) => {
                        fileToArrayBuffer(file).then((data) => {
                            setMobBuffer({ ...mobBuffer, tu: data })
                        })
                    }}
                >
                    <PreviewImage source="src" />
                </ImageInput>
                <TextInput initialValue={params.url} source="url" validate={required()} />
                <BooleanInput source="status" initialValue={params.status} />
                <DeleteButton />
            </SimpleForm>
        </> : '')
    )
}

export default ProfileInfoEdit;

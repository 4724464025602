import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  BooleanField,
  ImageField,
  TopToolbar,
  TabbedShowLayout,
  Tab,
  ArrayField,
  SingleFieldList,
  ChipField,
  EditButton,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import BackButton from '../backButton';

const useStyles = makeStyles({
  imgContainer: {
    '& img': {
      height: '150px',
      width: '150px',
      objectFit: 'contain',
      borderRadius: '50%',
    },
  },
});

const UserShow = (props) => {
  const classes = useStyles();

  const UserActions = ({ basePath, data, resource }) => (
    <TopToolbar>
      <BackButton color='primary' style={{ marginLeft: '5rem' }} />
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );

  return (
    <Show {...props} actions={<UserActions />}>
      <SimpleShowLayout>
        <TabbedShowLayout style={{backgroundColor: 'currentColor'}}>
          <Tab label='Profile' style={{color: 'white'}}>
            <TextField source='id' sortable={false} style={{color: 'white'}} />
            <ImageField
              source='profile'
              sortable={false}
              className={classes.imgContainer}
            />
            <TextField source='username' sortable={false} style={{color: 'white'}} />
            <TextField source='name' sortable={false} style={{color: 'white'}} />
            <TextField source='email' sortable={false} style={{color: 'white'}} />
            <TextField source='bio' sortable={false} style={{color: 'white'}} />
          </Tab>
          <Tab label='Categories' style={{color: 'white'}}>
            <ArrayField source='category'>
              <SingleFieldList>
                <ChipField source='categoryName.en' />
              </SingleFieldList>
            </ArrayField>
          </Tab>
          <Tab label='Role' style={{color: 'white'}}>
            <TextField source='role.id' label='Role' sortable={false}  style={{color: 'white'}}/>
            <TextField
              source='role.roleName'
              label='Role Name'
              sortable={false}
              style={{color: 'white'}}
            />
          </Tab>
          <Tab label='Wallet' style={{color: 'white'}}>
            <TextField source='walletAddress' sortable={false}  style={{color: 'white'}}/>
          </Tab>
          <Tab label='NFT' style={{color: 'white'}}>
            <TextField source='nftCreated' sortable={false} style={{color: 'white'}} />
          </Tab>
          <Tab label='Portfolio' style={{color: 'white'}}>
            <TextField source='portfolio.instagarm.username' sortable={false} style={{color: 'white'}} />
            <TextField source='portfolio.instagarm.url' sortable={false} style={{color: 'white'}} />
            <TextField source='portfolio.facebook.username' sortable={false} style={{color: 'white'}} />
            <TextField source='portfolio.facebook.url' sortable={false} style={{color: 'white'}} />
            <TextField source='portfolio.github.username' sortable={false} style={{color: 'white'}} />
            <TextField source='portfolio.github.url' sortable={false} style={{color: 'white'}} />
            <TextField source='portfolio.twitter.username' sortable={false} style={{color: 'white'}} />
            <TextField source='portfolio.twitter.url' sortable={false} style={{color: 'white'}} />
          </Tab>
          <Tab label='Other Details' style={{color: 'white'}}>
            <DateField source='createdAt' sortable={false}  style={{color: 'white'}}/>
            <BooleanField source='isActive' sortable={false} style={{color: 'white'}} />
            <BooleanField source='acceptedByAdmin' sortable={false} style={{color: 'white'}} />
            <TextField source='status' sortable={false} style={{color: 'white'}} />
          </Tab>
        </TabbedShowLayout>
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;

import { useState, useEffect } from "react";
import {
    SimpleForm, TextInput, required, ImageInput, ImageField, useRedirect,
    useNotify, Toolbar, SaveButton
} from 'react-admin';
import fileToArrayBuffer from 'file-to-array-buffer';
import dataProvider from "../../authentication/dataProvider";
import { services } from "../../services";
import ipfs from "../../config/ipfs";
import Loader from "../loading";


const CategoryEdit = (props) => {

    const notify = useNotify()
    const redirect = useRedirect()
    const [loading, setLoading] = useState(false)
    const [callAPI, setCallAPI] = useState(false)
    const [buffer, setBuffer] = useState(false)
    const [params, setParams] = useState({ id: null, categoryName: { en: null, tu: null } })

    useEffect(() => {

        async function fetcCategory() {
            const response = await dataProvider.getOne('categories', { id: props.id })
            if (response.data.image) {
                setParams({
                    id: response.data.id, image: response.data.image,
                    categoryName: { en: response.data.categoryName.en, tu: response.data.categoryName.tu }
                })
            } else {
                setParams({ id: response.data.id, categoryName: { en: response.data.categoryName.en, tu: response.data.categoryName.tu } })
            }
        }
        fetcCategory()

        async function updateCategory() {
            const request = services.put(`category/update/${params.id}`, params);
            request.then((response) => {
                setLoading(false) // stop loading.
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                if (response.status === 200) {
                    notify(response.data.message, `success`)
                    redirect('/categories')
                }
            })
                .catch((e) => {
                    setLoading(false) // stop loading.
                    if (e.response) {
                        if (e.response.status === 401 || e.response.status === 403) {
                            notify(`Unauthorized Request`, `error`)
                            localStorage.removeItem('token');
                            redirect('/categories')
                        }
                        // other error code (404, 500, etc): no need to log out
                    } else {
                        notify(`Bad request`, `error`)
                        redirect('/categories')
                    }
                });
        }

        if (callAPI) {
            updateCategory();
        }

    }, [callAPI])

    const CategoryEditToolbar = props => (
        <Toolbar {...props} >
            <SaveButton />
        </Toolbar>
    )

    const onSuccess = async (event) => {
        setLoading(true) // start loader
        let hash = params.image?.substring(params.image.lastIndexOf('/') + 1)
        if (buffer) {
            let hash = await ipfs.add(buffer, { // get buffer IPFS hash
                pin: true, progress: (bytes) => {
                    // console.log("File upload progress 1 ", Math.floor(bytes * 100 / (params.banner.en.rawFile.size)))
                }
            })
            setParams({ ...params, categoryName: { en: event.categoryName.en, tu: event.categoryName.tu }, image: hash.path })
        } else {
            setParams({ ...params, categoryName: { en: event.categoryName.en, tu: event.categoryName.tu }, image: hash })
        }
        setCallAPI(true)
    }

    const PreviewImage = ({ record, source }) => {
        if (typeof (record) == "string") {
            record = {
                [source]: record
            }
        }
        return <ImageField record={record} source={source} />
    }

    return (loading ? <Loader /> :
        <SimpleForm toolbar={<CategoryEditToolbar />} save={onSuccess} style={{backgroundColor: 'currentColor'}}>
            <TextInput disabled label="Id" source="id" initialValue={params.id}  />
            <ImageInput initialValue={params.image} source="image" label="Category Image"
                placeholder="Upload the image." accept="image/*" multiline={false}
                onChange={async (file) => {
                    fileToArrayBuffer(file).then((buffer) => {
                        setBuffer(buffer)
                    })
                }}
            >
                <PreviewImage source="image" />
            </ImageInput>
            <TextInput source="categoryName.en"
                onChange={(e) => {
                    params.categoryName.en = e.target.value;
                }}
                validate={required()} initialValue={params.categoryName.en}  />
            <TextInput source="categoryName.tu"
                onChange={(e) => {
                    params.categoryName.tu = e.target.value;
                }}
                validate={required()} initialValue={params.categoryName.tu}  />
        </SimpleForm>
    )
}

export default CategoryEdit;

import * as React from "react";
import { useState, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import ReactPlayer from 'react-player';
import {
    Show, SimpleShowLayout, useRedirect, useNotify, TextField, DateField, useRefresh,
    TopToolbar, BooleanField, ImageField, TabbedShowLayout, Tab, ArrayField,
    SingleFieldList, ChipField, EditButton,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import BackButton from "../backButton";
import { services } from "../../services";
import Loader from "../loading";
import { getFileType } from "../../helper/function";
import VideoCover from "../../video-cover.jpg";


const useStyles = makeStyles({
    imgContainer: {
        '& img': {
            height: '250px',
            width: '250px',
            objectFit: 'contain',
        }
    },
    videoContainer: {
        '& img': {
            height: '150px',
            width: '250px',
            objectFit: 'contain',
        }
    }
})

const RenderNFT = (record) => {

    const classes = useStyles();
    const [ext, setExt] = useState(false);

    useEffect(() => {
        function getExtenstion() {
            if (!record.image.format) {
                let extenstion = getFileType(record.image.compressed);
                extenstion.then(function (result) {
                    setExt(result)
                })
            } else {
                setExt(record.image.format)
            }
        }
        getExtenstion();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record]);

    if (ext && ext === `image`) return <ImageField source="image.original" sortable={false} label="Image" className={classes.imgContainer} />
    if (ext && ext === `audio`) return <ReactAudioPlayer src={record.image.compressed} controls />
    if (ext && ext === `video`) return <ReactPlayer controls={true} url={record.image.original} playIcon={<></>} width='450px' height='250px' light={VideoCover} />
    else return 'loading..'
}

const NFTShow = (props) => {

    const [apiCall, setAPICalled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(false);
    const [nftId, setNftId] = useState(null);

    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const classes = useStyles();


    useEffect(() => {

        async function disabledNFT() {
            setLoading(true) // start loading. 
            const params = { "isActive": status }
            const request = services.put('nft/updateNft/' + nftId, params);
            request.then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    setLoading(true) // start loading. 
                    throw new Error(response.statusText);
                }
                if (response.status === 200) {
                    setLoading(true) // start loading. 
                    notify(response.data.message, `success`)
                    redirect('/nft');
                }
            })
                .catch((e) => {
                    setLoading(true) // start loading. 
                    if (e.response) {
                        if (e.response.status === 401 || e.response.status === 403) {
                            notify(`Unauthorized Request`, `error`)
                            localStorage.removeItem('token');
                            refresh()
                        }
                        // other error code (404, 500, etc): no need to log out
                    } else {
                        notify(`Bad request`, `error`)
                        refresh()
                    }
                });
        }

        if (apiCall) {
            disabledNFT()
        }
    }, [apiCall])

    const NFTDisableClick = ({ _id, isActive }) => {
        setNftId(_id)
        setAPICalled(true)
        setStatus(isActive ? false : true)
    }

    const NFTShowActions = ({ basePath, data, resource }) => (
        <TopToolbar>
            <BackButton
                // variant='outlined'
                color='primary'
                style={{ marginLeft: '5rem' }} />
            {/* Add your custom actions */}
            <EditButton basePath={basePath} record={data} />
            <Button color="primary" onClick={() => NFTDisableClick(data)} >{data ? data.isActive ? 'Disable' : 'Enable' : '--'}</Button>
        </TopToolbar>
    )

    const CustomShawLayout = ({ record }) => {
        return (
            <SimpleShowLayout>
                <TabbedShowLayout style={{ backgroundColor: 'currentcolor' }}>
                    <Tab label='NFT' style={{ color: 'white' }}>
                        <TextField source="id" sortable={false} style={{ color: 'white' }} />
                        <TextField source="title" sortable={false} style={{ color: 'white' }} />
                        <RenderNFT {...record} style={{ color: 'white' }} />
                        <DateField source="createdAt" sortable={false} style={{ color: 'white' }} />
                    </Tab>
                    <Tab label='NFT Info' style={{ color: 'white' }}>
                        <TextField source="edition" sortable={false} style={{ color: 'white' }} />
                        <BooleanField source="isActive" sortable={false} style={{ color: 'white' }} />
                        <TextField source="price" sortable={false} style={{ color: 'white' }} />
                        <TextField source="saleState" sortable={false} style={{ color: 'white' }} />
                        <BooleanField source="unlockContent" sortable={false} style={{ color: 'white' }} />
                        <TextField source="nftSold" sortable={false} style={{ color: 'white' }} />
                    </Tab>
                    <Tab label='Category' style={{ color: 'white' }}>
                        <ArrayField source='category'>
                            <SingleFieldList>
                                <ChipField source='categoryName.en' />
                            </SingleFieldList>
                        </ArrayField>
                    </Tab>
                    <Tab label='Owner Details' style={{ color: 'white' }}>
                        <TextField source="ownerId.id" sortable={false} style={{ color: 'white' }} />
                        <TextField source="ownerId.name" sortable={false} style={{ color: 'white' }} />
                        <TextField source="ownerId.username" sortable={false} style={{ color: 'white' }} />
                        <ImageField
                            source='ownerId.profile'
                            sortable={false}
                            className={classes.imgContainer}
                        />
                    </Tab>
                </TabbedShowLayout>
            </SimpleShowLayout >
        )
    }

    return loading ? <Loader /> :
        <Show actions={< NFTShowActions />} {...props}>
            <CustomShawLayout />
        </Show >
}

export default NFTShow;
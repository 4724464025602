import { services } from "../../services";
let networkId = 1;
async function fetchNetworkId() {
  networkId = await services.getNetworkId();
}
fetchNetworkId();

function getContractAddresses() {
  if (networkId === "0x13881" || +networkId === 80001)
    return {
      token: "0xFA0A30bd853a29a09038772A96E66DB09BB6Be59", //0x7862A31a1a1184882CEe3Aec91Bcc93B0512D09F
      nftContractAddress: "0xc7f14c8AFa4050C45EC49fEbD0E2860Af62a6f3d", //0x932a362d89b60FE93e5d84E009E2C956578Ab536
    };
  else if (+networkId === 56 || networkId === "0x38")
    return {
      token: "0xF70c1addE0d0a56A07bac31dfE096E5BC567bF44",
      nftContractAddress: "0x394a0C3Ac1674bFa7778743f431b4801b51aeF6a",
      escrowContractAddres: "0x224062036E77E54F30c41cE88082BCf93b4A1Bd1",
    };
  else
    return {
      token: "0xFA0A30bd853a29a09038772A96E66DB09BB6Be59", //0xF70c1addE0d0a56A07bac31dfE096E5BC567bF44
      nftContractAddress: "0xc7f14c8AFa4050C45EC49fEbD0E2860Af62a6f3d", //0x394a0C3Ac1674bFa7778743f431b4801b51aeF6a
      escrowContractAddres: "0x179428199C069033Bb0Fb5346C63a8D27CE495dD", //0x224062036E77E54F30c41cE88082BCf93b4A1Bd1
    };
}
export default getContractAddresses;

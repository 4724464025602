import { services } from "../services";

export const adminActions = {
  getSwitches,
  updateSwitches,
};


function getSwitches() {
  const response = services.get(`/admin/dashboard/list`);
  return response.then((promise) => {
    if (promise) {
      return promise.data.data
    } else {
      console.log("error in actions get switches ");
    }
  });
}

function updateSwitches(id, params) {
  const response = services.put(`admin/dashboard/update/${id}`, params);
  return response.then((promise) => {
    if (promise) {
      return promise.data
    } else {
      console.log("error in actions update switches ");
    }
  });
}